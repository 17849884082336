import React from "react"
import Foto from "@components/pageCasosdeExito/image/imgPhotoEmpresa/auna/img1.png"
import Foto2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/auna/img2.png"

//logo de empresas clientes
import Limautos from "@components/pageCasosdeExito/image/logoscustomer/Auna.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import mensajes from "@components/pageCasosdeExito/image/iconcards/mensajes.svg"
import chatbot from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/auna/img1.svg"

const text1 = (
  <span className="">
    AUNA, con una trayectoria de más de 30 años, es una compañía latinoamericana que busca transformar el cuidado de la salud y el bienestar en la región. Actualmente, tiene presencia en más de 3 países de Latinoamérica, con más de 8,500 colaboradores directos e indirectos, dedicados a cuidar la salud en cada momento de la vida de afiliados y pacientes.
    <br /> <br />
    Con la implementación de Beex, AUNA ha logrado incrementar en 5 puntos porcentuales su tasa de conversión gracias a una estrategia de automatización para la gestión de leads y el seguimiento de estos.
  </span>
)

const text2 = (
  <span>
    AUNA ha desarrollado una red de clínicas y centros de bienestar; servicios de salud transversales; productos de protección de salud general y oncológica; y, todo ello, siempre colocando al cliente al centro de la experiencia, con el soporte de la investigación médica y académica más avanzada.
    <br /> <br />
    Graciela Zapater, Especialista Digital en AUNA, ha sido el contacto directo entre la marca y Beex para la automatización del canal de WhatsApp en los procesos de gestión y seguimiento de leads. Gracias a un trabajo en conjunto entre su equipo y la ayuda del equipo de operaciones de Beex, la organización pudo acceder rápidamente a la API de WhatsApp Business.
    <br /> <br />
    Parte de sus objetivos clave era aumentar el cierre de prospección de sus planes de protección de los leads que ingresan directamente por whatsapp y aumentar la contactabilidad de los leads de telemarketing. Por ello, vieron a Beex como un gran aliado que les permitiría tener la visibilidad de la gestión en tiempo real que requerían, dar el seguimiento adecuado a los leads a través de la gestión de agendamientos, el redireccionamiento de leads al skill correcto y el despliegue de campañas masivas para poder aumentar la contactabilidad de los leads.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
   “Beex es aplicable para distintos frentes dentro del sector salud. En nuestro caso, lo utilizamos para optimizar nuestra prospección de leads y cierre de ventas, lo que nos ha ayudado a incrementar la conversión, debido a la optimización de la gestión a través de la herramienta.”
  </p>
)

const text4 = ""

const text5 = (
  <span>
    Gracias a la colaboración efectiva entre los equipos de AUNA y Beex, la implementación de Beex y la API de WhatsApp Business ha sido un éxito rotundo. Desde el inicio, se trabajó en la integración completa entre el CRM de AUNA y Beex, logrando una sinergia perfecta a través del uso de APIs.
    <br /> <br />
    Esta integración permite a Beex manejar la información de manera eficiente y en tiempo real, facilitando la gestión y seguimiento de leads mediante WhatsApp, al activar el envío de plantillas personalizadas. Cada interacción con los leads, ya sea a través de la prospección, seguimiento o conversión, se refleja instantáneamente en el sistema, lo que ha mejorado significativamente la eficiencia operativa.
    <br /> <br />
    Con Beex, AUNA ha optimizado la gestión de sus leads, lo que ha llevado a un incremento la conversión por WhatsApp. Este aumento es el resultado de una combinación de estrategias internas y la utilización avanzada de la plataforma. Beex no solo permite un seguimiento más detallado y organizado de los leads, sino que también proporciona herramientas para análisis y reportería en tiempo real, permitiendo tomar decisiones informadas y oportunas.
    <br /> <br />
    Graciela, señala que la capacidad de Beex para integrarse y adaptarse a las necesidades específicas de AUNA ha sido fundamental. La plataforma ofrece una visión integral y en tiempo real del estado de los leads, permitiendo a los equipos de ventas y marketing reaccionar rápidamente y ajustar sus estrategias según sea necesario.
  </span>
)
const text6 = (
  <span>
    “Beex ha sido clave en la transformación de la gestión de los leads dentro de nuestro canal, optimizando procesos y mejorando los resultados. Esta colaboración ha demostrado ser una solución poderosa y flexible, adaptándose a las necesidades de la empresa y fortaleciendo su capacidad para convertir leads en clientes satisfechos.”
  </span>
)

const text7 = (
  <span>
    Gracias a la implementación de Beex, AUNA ha potenciado su gestión de leads mediante la creación de un autoservicio en WhatsApp. Utilizando chatbots, AUNA permite a sus leads acceder fácilmente a cotizaciones o asesorías en tiempo real. Este flujo de trabajo, creado desde Beex, ha optimizado la eficiencia y mejorado la experiencia del usuario al derivar automáticamente los leads de atención al cliente a su canal respectivo
    <br /> <br />
    El uso de Beex ha permitido automatizar y agilizar procesos clave, reduciendo tiempos de respuesta y aumentando la satisfacción del cliente. La integración del enrutamiento inteligente ha sido crucial, permitiendo que los leads sean atendidos por el agente más adecuado según sus necesidades específicas.
  </span>
)
const text8 = (
  <span>
    Beex ayuda a gestionar leads, optimizando la capacidad de seguimiento y brindando la visibilidad en tiempo real que se necesita. Con la plataforma se puede monitorear constantemente todas las interacciones con los leads, permitiendo una medición precisa de las iniciativas.
    <br /> <br />
    También, ofrece reportería y seguimiento en tiempo real, lo que permite analizar el rendimiento de las campañas y ajustar las estrategias según sea necesario. Además, el monitoreo constante permite proporcionar feedback inmediato a los agentes, asegurando que puedan reaccionar rápidamente y mejorar la calidad de la atención al cliente.
    <br /> <br />
    Las funcionalidades de Beex para la gestión multiagente, mensajes masivos y la supervisión en tiempo real han sido esenciales. Estas características permiten monitorear las actividades de los agentes, proporcionar feedback inmediato y asegurarse de que los leads se gestionen de manera eficiente.
  </span>
)

const text9 = (
  <span>
    “Es una plataforma amigable y sencilla de entender y utilizar. Cubre la mayoría de las necesidades de una plataforma de gestión de WhatsApp. Es bastante flexible a cambios por necesidades internas del cliente.”
  </span>
)

const text10 = (
  <span>
    Sin duda, es una experiencia grata para Beex seguir ayudando a AUNA en la mejora de su gestión de leads. El entendimiento entre equipos, tanto a nivel comercial como de soporte, ha sido de mucha ayuda para desplegar toda esta estrategia de automatización.
  </span>
)


const data = {
  intro: {
    image: Limautos,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: Foto,
    text4: text4,
    title5: "Prospección con WhatsApp Business API",
    text5: text5,
    text6: text6,
    title7: "Autoservicio en WhatsApp",
    text7: text7,
    title8: "Monitoreo en tiempo real",
    text8: text8,
    fototwo: Foto2,
    text9: text9,
    text10: text10,

    metric1: metrica1,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Optimización en la gestión y seguimiento de leads mediante la API de WhatsApp Business, proporcionando una mejora en la interacción con sus clientes potenciales. Además, con un enrutamiento inteligente que asegura que cada lead reciba atención personalizada.",
    },
    {
      title: "Chatbot",
      img: chatbot,
      info:
        "Los bots permiten un autoservicio que automatiza la interacción con los leads, ofreciendo respuestas rápidas y precisas. Esta funcionalidad permite gestionar consultas y cotizaciones de manera eficiente, mejorando la conversión y la satisfacción del cliente.",
    },
    {
      title: "Mensajes masivos",
      img: mensajes,
      info:
        "Creación de campañas de mensajes masivos, mejorando el seguimiento de leads. Esta funcionalidad asegura que la información llegue a los grupos adecuados, optimizando las campañas y facilitando una gestión más precisa y efectiva de los leads.",
    },
  ],
}

export default data
