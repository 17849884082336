/* eslint-disable no-undef */
import React from "react"
import StructurePageAuna from "@components/pageCasosdeExito/template/templatePageAuna"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: AUNA [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/auna/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Entérate cómo AUNA ha logrado incrementar en 5pp su tasa de conversión gracias a una estrategia de automatización para la gestión y seguimiento de leads." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: AUNA" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/auna/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-auna.jpg`}
      />
      <meta property="og:description" content="Entérate cómo AUNA ha logrado incrementar en 5pp su tasa de conversión gracias a una estrategia de automatización para la gestión y seguimiento de leads." />
    </Helmet>
    <StructurePageAuna location={location} />
  </div>
)

export default IndexPage
